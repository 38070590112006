<template>
  <v-dialog
    v-model="open"
    fullscreen
    transition="fade-transition"
  >
    <v-card
      color="grey darken-4"
      dark
    >
      <v-app-bar
        color="rgba(0, 0, 0, .6)"
        fixed
        flat
      >
        {{ imageIndex + 1 }} / {{ pictures.length }}
        <v-spacer />

        <v-btn
          class="mx-1 mx-md-3"
          icon
          small
          @click="zoomed = !zoomed"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          class="mx-1 mx-md-3"
          icon
          small
          @click="toggleFullscreen"
        >
          <v-icon>mdi-arrow-expand-all</v-icon>
        </v-btn>

        <v-btn
          class="mx-1 mx-md-3"
          icon
          small
          @click="$emit('close', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-responsive min-height="100vh">
        <v-container
          class="pa-0 fill-height"
          fluid
        >
          <v-row
            align="center"
            class="fill-height mx-0"
            style="max-width: 766;"
          >
            <v-carousel
              v-model="imageIndex"
              height="700"
              hide-delimiters
            >
              <v-carousel-item
                v-for="(pic, i) in pictures"
                :key="i"
                :class="{
                  'v-carousel-item--zoomed': zoomed
                }"
              >
                <v-img :src="pic" contain max-height="700"></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-row>
        </v-container>
      </v-responsive>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'GalleryCarousel',
  props: ['dialogOpen', 'source'],
  data: () => ({
    isFullscreen: false,
    zoomed: false,
    open: false
  }),
  computed: {
    imageIndex: {
      get: function () {
        return this.$store.state.gallery.imageIndex
      },
      set: function (index) {
        this.$store.commit('gallery/SET_IMAGE_INDEX', index)
      }
    },
    pictures () {
      return this.$store.state.gallery[this.source]
    }
  },
  watch: {
    dialogOpen (val) {
      this.open = val
    }
  },
  methods: {
    toggleFullscreen () {
      if (document.fullscreenElement) {
        document.exitFullscreen()
        this.isFullscreen = false
      } else {
        document.documentElement.requestFullscreen()
        this.isFullscreen = true
      }
    }
  }
}
</script>

<style lang="sass">
  .v-carousel-item--zoomed .v-image__image
    transform: scale(1.2)
</style>
